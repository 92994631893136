<template>
    <div class="page">
        <div class="content">
            <div class="title">
                <div>
                    <span>人才招聘</span>
                </div>
            </div>
            <div class="text_con">

            </div>
            <div class="genre_box">
                <div class="genre_box_tab">
                    <el-tabs v-model="activeName" :stretch="true" @tab-click="handleClick">
                        <el-tab-pane label="研发" name="research">
                            <research :researchValue="researchArr"/>
                        </el-tab-pane>
                        <el-tab-pane label="商务" name="affairs">
                            <affairs :affairsValue="affairsArr"/>
                        </el-tab-pane>
                        <el-tab-pane label="职能" name="duty">
                            <duty :dutyValue="dutyArr"/>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import research from './research.vue'
    import affairs from './affairs.vue'
    import duty from './duty.vue'

    import {postApiConfig} from "@/api/apiConfig.js";

    export default {
        name: "box_1",
        components:{
            research,
            affairs,
            duty,
        },
        data(){
            return{
                bannerHeight:'',
                activeName:"research",
                //
                infoList:'',
                researchArr:[],
                affairsArr:[],
                dutyArr:[],
            }
        },
        mounted() {
            // this.imgLoad();
            window.addEventListener('resize',() => {
                this.bannerHeight = this.$refs.bannerHeight.height;
                this.imgLoad();
            },false);

            this.getList();
        },
        methods: {
            imgLoad() {
                this.$nextTick(() => {
                    this.bannerHeight = this.$refs.bannerHeight.height;
                })
            },
            handleClick(tab, event) {
                console.log(tab, event);
            },
            getList(){
                postApiConfig('/pc/page/recruit',{}).then((res) => {
                    // console.log("岗位信息",res)
                    this.infoList = res.data.records
                    this.infoList.forEach((item) =>{
                        switch (item.type) {
                            case 1:
                                this.researchArr.push(item);
                                break
                            case 2:
                                this.affairsArr.push(item);
                                break
                            case 3:
                                this.dutyArr.push(item);
                                break
                        }
                    })
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/style/tabs.css";
    .page {
        width: 100%;
        background-color: #f6f7fb;

        .content {
            width: 1200px;
            padding: 100px 0;
            margin: auto;

            .title {
                width: 100%;
                color: #040030;
                text-align: center;

                div {
                    &:nth-child(1) {
                        color: #040030;
                        font-size: 44px;
                    }
                }
            }

            .text_con {
                width: 100%;
            }
            .genre_box {
                margin-top: 30px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                .genre_box_tab{
                    width: 100%;
                }
            }
        }
    }
</style>

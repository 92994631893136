<template>
    <div class="page">
        <div class="content">
            <div class="title">
                <div class="title_box">
                    <span>优秀产品</span>
                </div>
            </div>
            <div class="text">
                <div v-for="(item,index) in productList" :key="index" class="text_box" :class="index % 2 == 0 ? 'text_box_a':'text_box_b'">
                    <div v-if="item.small" class="product_box" @click="product(item)">
<!--                        <img :src="imgUrl + item.small" alt="" :class="item.type == 1 ?'typeA':'typeB'">-->
                        <img :src="imgUrl + item.small" alt="" class="typeA">
                    </div>
                    <div v-else class="product_box" @click="goCasesDetails">
                        <div style="text-align: center;">
                            <span >查看更多</span><br>
                            <i class="el-icon-right" style="color: #0A68FF;font-size: 30px;margin-top: 5px;"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="icon">

            </div>
        </div>
    </div>
</template>

<script>
    import {postApiConfig} from "@/api/apiConfig.js";
    export default {
        name: "box_3",
        data(){
            return{
                productList:[],
            }
        },
        mounted() {
            this.$nextTick(()=>{
                this.getCasesList();
            })
        },
        methods:{
            getCasesList(){
                let data = {
                    current: 1,
                    size: 5,
                    // type: 1,
                }
                postApiConfig("/pc/page/product",data).then((response)=>{
                    // console.log("首页-产品",response)
                    this.productList = response.data.records
                    this.productList.push({})
                });
            },
            product(item){
              console.log("item",item)
                this.$router.push({
                    path: `/productDetails`,
                    query: { res: item.id },
                });
            },
            // 查看更多
            goCasesDetails(){
                this.$router.push({path: `/product`});
            }
        }
    }
</script>

<style scoped lang="scss">
    .page {
        width: 100%;
        background-size: 100% 100%;

        .content {
            width: 1200px;
            padding: 100px 0;
            margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .title{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                .title_box{
                    width: 40%;
                    background-image: url("../../assets/img/home/icon_1.png");
                    background-repeat: no-repeat;
                    background-position: center center;
                    text-align: center;
                    span{
                        color: #333333;
                        font-size: 32px;
                    }
                }
            }

            .text{
                width: 100%;
                margin-top: 30px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                .text_box{
                    width: 400px;
                    height: 400px;
                    background-color: #1882FF;
                    .product_box{
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .typeA{
                            width: auto;
                            height: 60%;
                        }
                        .typeB{
                            width: 100%;
                            height: 100%;
                        }
                        span{
                            color: #0A68FF;
                            font-size: 34px;
                        }
                    }

                }
                .text_box_a{
                    background-color: #f6f7fb;
                }
                .text_box_b{
                    background-color: #e8ecf0;
                }
            }

            .icon{
                width: 100%;
            }
        }
    }
</style>

<template>
    <div class="page">
        <div class="content">
            <div class="title">
                <div class="title_box">
                    <span>成功案例</span>
                </div>
            </div>
            <div class="text">
                <el-carousel ref="carousel" indicator-position="none" :height="casesHeight+'px'" :autoplay="autoplay" @change="slideChange">
                    <el-carousel-item v-for="(item,index) in casesList" :key="index">
                        <div class="casesBox" @mouseenter="stop()" @mouseleave="up()">
                            <div class="casesLeft">
                                <img ref="casesHeight" :src="imgUrl + item.small" alt="" @load="imgLoad">
                            </div>
                            <div class="casesRight">
                                <div class="casesRight_title">
                                    <div>
                                        <span>
                                            {{ item.name }}
                                        </span>
                                    </div>
                                    <div class="icon_box"></div>
                                </div>
                                <div class="casesRight_text">
                                    <span>
                                        {{ item.blurb }}
                                    </span>
                                </div>
                                <div class="casesRight_button">
                                    <el-button @click="goDetails(item,index)">
                                        查看详情
                                        <i class="el-icon-right"></i>
                                    </el-button>
                                </div>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="icon">
                <div v-for="(item,index) in casesList" :key="index" class="icon_con" @click="clickCases(index)" @mouseenter="stop()" @mouseleave="up()">
                    <div :class=" index === timeIndex ? 'icon_box_new':'icon_box'">{{ item.name }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {postApiConfig} from "@/api/apiConfig.js";

    export default {
        name: "box_2",
        data(){
            return{
                casesHeight:'481',
                casesList:[],
                time:'',
                timeIndex: 0,
                autoplay: true,
            }
        },
        mounted() {
            this.$nextTick(()=>{
                this.getCasesList();
            })
        },
        methods:{
            imgLoad(){
                this.$nextTick(() => {
                    // this.casesHeight = this.$refs.casesHeight[0].height;
                })
            },
            getCasesList(){
                let data = {
                    current: 1,
                    size: 5,
                }
              postApiConfig("/pc/page/cases",data).then((response)=>{
                  // console.log("首页-成功案例",response)
                  this.casesList = response.data.records
                  this.casesTime();
              });
            },
            stop(){
                this.autoplay = false
                clearInterval(this.time)
            },
            up(){
                this.autoplay = true
                this.casesTime();
            },
            casesTime(){
                this.time = setInterval(()=>{
                    this.timeIndex++;
                    if (this.timeIndex === 5){
                        this.timeIndex = 0
                    }
                },3000)
            },
            goDetails(item){
                this.$router.push({
                    path: `/casesDetails`,
                    query: { res: item.id },
                });
            },
            slideChange(index){
                clearInterval(this.time)
                this.timeIndex = index;
            },
            clickCases(item){
                // console.log(item)
                clearInterval(this.time)
                this.timeIndex = item;
                this.$refs.carousel.setActiveItem(item)
            }
        }
    }
</script>

<style scoped lang="scss">
    .page {
        width: 100%;
        background-color: #F9FAFD;
        background-size: 100% 100%;

        .content {
            width: 1200px;
            padding: 100px 0;
            margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .title{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                .title_box{
                    width: 40%;
                    background-image: url("../../assets/img/home/icon_1.png");
                    background-repeat: no-repeat;
                    background-position: center center;
                    text-align: center;
                    span{
                        color: #333333;
                        font-size: 30px;
                    }
                }
            }

            .text{
                width: 100%;
                margin-top: 30px;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
                .casesBox{
                    width: 100%;
                    height: 481px;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    .casesLeft{
                        width: 530px;
                        height: 481px;
                        box-sizing: border-box;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .casesRight{
                        flex: 1;
                        height: 481px;
                        padding: 20px;
                        box-sizing: border-box;
                        display: flex;
                        justify-content: space-around;
                        flex-direction: column;
                        .casesRight_title{
                            width: 100%;
                            color: #040030;
                            font-size: 32px;
                            .icon_box{
                                margin-top: 20px;
                                display: inline-block;
                                background-color: #2478FF;
                                width: 50px;
                                height: 4px;
                                border-radius: 2px;
                            }
                        }
                        .casesRight_text{
                            width: 100%;
                            color: #303753;
                            font-size: 18px;
                            //文本超出部分以...形式展示
                            text-overflow: -o-ellipsis-lastline;
                            //整体超出部分隐藏
                            overflow: hidden;
                            //文本超出部分以...形式展示，同第一行样式代码
                            text-overflow: ellipsis;
                            //display 块级元素展示
                            display: -webkit-box;
                            //设置文本行数为2行
                            -webkit-line-clamp: 2;
                            //设置文本行数为2行
                            line-clamp: 2;
                            //从上到下垂直排列子元素（设置伸缩盒子的子元素排列方式）
                            -webkit-box-orient: vertical;
                        }
                        .casesRight_button{
                            width: 100%;
                        }
                    }
                }
            }

            .icon{
                width: 100%;
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-flow: nowrap;
                .icon_con{
                    width: 17%;
                    box-sizing: border-box;
                    height: 50px;
                    .icon_box{
                        color: #999999;
                        line-height: 50px;
                        text-align: center;
                        position: relative;
                        cursor:pointer;
                        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
                    }
                    .icon_box_new{
                        color: #2478FF;
                        line-height: 50px;
                        text-align: center;
                        position: relative;
                        cursor:pointer;
                        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
                        &::after{
                            content: "";
                            display: inline-block;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 4px;
                            box-sizing: border-box;
                            background-color: #2478FF;
                        }
                    }
                }

            }
        }
    }
</style>

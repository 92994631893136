<template>
    <div class="page">
        <!-- 轮播图开始 -->
        <div class="slideshow">
            <el-carousel indicator-position="none" arrow="never" :height="(bannerHeight-5)+'px'">
                <el-carousel-item>
                    <img ref="bannerHeight" src="../../assets/img/joinUs/bg_1.png" alt="" @load="imgLoad">
                    <div class="slogan">
                        <div class="item">
                            <div class="assistant">
                                <!--                我们提供最好的-->
                                {{ bigTopic }}
                            </div>
                            <div class="main">
                                <!--                IT解决方案-->
                                {{ smallTopic }}
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <!-- 导航栏开始 -->
        <div class="nav_wrap" :class=" scrollTopVal > 100 ? 'bgColor' : 'bgColorNo'">
            <nav>
                <div class="nav_right">
                    <img @click="selectImg" :src="logoImg" alt="六湛logo" class="logo_img"/>
                </div>
                <div class="nav_left">
                    <ul>
                        <li @click="selectNav(item)" class="item itemTitle" v-for="(item, index) in navData" :key="index">
                            <span :class="{ checked: item.path == selectUrl }" v-if=" scrollTopVal < 300 ">
                                {{item.name}}
                            </span>
                            <span :class="{ rollChecked: item.path == selectUrl }" v-else>
                                {{item.name}}
                            </span>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
        <!-- 简介 -->
        <div class="brief">
            <div class="brief_main">
                <div class="brief_title">
                    <span>
<!--                        Andorid开发工程师-->
                        {{ workList.postName }}
                    </span>
                    <span>
                        <el-button type="primary" @click="joinWork(workList.id)">投 递</el-button>
                    </span>
                </div>
                <div class="place">
                    <span>
<!--                        南昌市-->
                        {{ workList.city }}
                    </span>
                    <span>|</span>
                    <span>
<!--                        3-5年-->
                        {{ workList.workTime }}
                    </span>
                    <span>|</span>
                    <span>
<!--                        大专以上-->
                        {{ workList.education }}
                    </span>
                </div>
                <div class="duty">
                    <div class="duty_title">
                        <!--                        <span>岗位职责</span>-->
                    </div>
                    <div class="duty_text">
                        <span v-html="workList.job"></span>
                        <!--                        <span>{{ workList.job }}</span>-->
                    </div>
                </div>
                <!--                <div class="duty">-->
                <!--                    <div class="duty_title">-->
                <!--&lt;!&ndash;                        <span>职位要求</span>&ndash;&gt;-->
                <!--                    </div>-->
                <!--                    <div class="duty_text">-->
                <!--                        <span v-html="workList.require">-->
                <!--&lt;!&ndash;                           1、计算机或相关专业专科以上学历，1年及以上Android平台开发经验；<br>  2、熟悉Android开发平台框架及各种特性，能够&ndash;&gt;-->
                <!--                        </span>-->
                <!--                    </div>-->
                <!--                </div>-->
            </div>
        </div>
        <!-- foot -->
        <div class="footerWrap">
            <div class="footBox">
                <div class="main">
                    <div class="footBox_left">
                        <div>
                            <ul>
                                <li class="title"><span>导航</span></li>
                                <li @click="selectNav(item)" class="item" v-for="(item, index) in navData" :key="index">
                                    <span>{{item.name}}</span>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div>
                                <span>地址：</span><span>{{ footList.address }}</span>
                            </div>
                            <div>
                                <span>电话：</span><span>{{ footList.phone }}</span>
                            </div>
                            <div>
                                <span>邮箱：</span><span>{{ footList.mail }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="footBox_right">
                        <div>
                            <img :src="imgUrl + footList.code" alt="">
                        </div>
                        <div>
                            <span>了解更多请关注我们</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="internetWrap">
                <div class="internetBox">
                    <div>
                        <span>版权所有：{{ footList.information }}</span>
                    </div>
                    <div>
                        <!--                    https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=36010802000569-->
                        <a href="https://www.beian.gov.cn/portal/registerSystemInfo" target="_blank">
                        <span>
                            <img src="../../assets/img/footer/1.png" alt="" />
                            {{ footList.recordNumber }}
                        </span>
                        </a>
                    </div>
                    <div>
                        <a href="https://beian.miit.gov.cn" target="_blank">
                        <span>
                            {{ footList.domain }}
                        </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- 投递 -->
        <div>
            <el-dialog title="" :visible.sync="open" width="600px" >
                <div class="delivery">
                    <el-form :rules="rules" ref="form" :model="form" label-width="80px" label-position="top">
                        <div>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <div>
                                        <el-form-item label="姓名" prop="name">
                                            <el-input v-model="form.name"></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div>
                                        <el-form-item label="应聘岗位" prop="position">
                                            <el-select v-model="form.position" placeholder="请选择" :style="{width: 100 + '%'}" @change="changePost">
                                                <el-option v-for="(item,index) in infoList" :key="index" :label="item.postName" :value="item.id"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                        <div>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <div>
                                        <el-form-item label="联系方式" prop="phone">
                                            <el-input v-model="form.phone"></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div>
                                        <el-form-item label="邮箱" prop="mail">
                                            <el-input v-model="form.mail"></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                        <el-form-item label="留言备注" prop="remark">
                            <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="form.remark">
                            </el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div style="text-align: right">
                    <el-button @click="reset">取 消</el-button>
                    <el-button type="primary" @click="submitForm">确 定</el-button>
                </div>
                <!--                <span slot="footer" class="dialog-footer">-->
                <!--                    -->
                <!--                </span>-->
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import transparentImg from "../../assets/img/home/lz_logo.png";
    import { workJs } from "../../utils/work.js";

    import {postApiConfig} from "@/api/apiConfig.js";

    export default {
        name: "details",
        data(){
            return{
                // 获取当前屏幕的高
                // bgHeight: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
                bannerHeight:'',
                logoImg:transparentImg,
                checked: false,
                navData: [],
                selectUrl: null,
                slideshowData: [],
                scrollTopVal:'',
                bigTopic:'',
                smallTopic:'人才招聘进行中',
                workList:workJs(),
                res:'',
                val:'',
                //
                open:false,
                form:{},
                rules:{
                    name:[{required: true, message: '请输入姓名', trigger: 'blur'}],
                    position:[{required: true, message: '请输入应聘岗位', trigger: 'blur'}],
                    phone:[{required: true, message: '请输入联系方式', trigger: 'blur'}],
                    mail:[{required: false, message: '请输入邮箱', trigger: 'blur'}],
                    remark:[{required: true, message: '请输入留言备注', trigger: 'blur'}],
                },
                workListStr:[],

                //
                infoList:'',
                footList:'',
            }
        },
        watch: {
            // 监听路由
            $route(to) {
                this.selectUrl = to.path;
            },
        },
        created() {
            this.get_nav();
            this.getList();
            this.getFootList()
            this.val = this.$route.query.val;

        },
        mounted() {
            // this.imgLoad();
            window.addEventListener('resize',() => {
                this.bannerHeight = this.$refs.bannerHeight.height;
                this.imgLoad();
            },false)

            window.addEventListener("scroll", this.handleScroll, true);

        },
        methods:{
            handleScroll() { // 监听鼠标滚动,页面滚动距顶部距离
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
                this.scrollTopVal = scrollTop
                // console.log("距离多少",scrollTop)
                let navWrap = document.getElementsByClassName("nav_wrap")[0];
                let itemTitle = document.getElementsByClassName("itemTitle")
                // console.log(itemTitle)
                if (scrollTop < 300) {
                    // navLeft
                    // 鼠标上滚  执行的的方法
                    // navWrap.style.backgroundColor = "rgba(255,255,255,0)";
                    navWrap.style.transition = "1s";
                    for (let i = 0; i < itemTitle.length; i++) {
                        itemTitle[i].style.color = "#4C4C65";
                        itemTitle[i].style.transition = "1s";
                    }
                    this.logoImg = transparentImg
                } else {
                    // 鼠标下滚  执行的的方法
                    // navWrap.style.backgroundColor = "rgba(255,255,255,1)";
                    navWrap.style.transition = "1s";
                    for (let i = 0; i < itemTitle.length; i++) {
                        itemTitle[i].style.color = "#4C4C65";
                        itemTitle[i].style.transition = "1s";
                    }
                }
            },
            getList(){
                postApiConfig("/pc/page/recruit",{}).then((res) => {
                    // console.log("岗位信息",res)
                    this.infoList = res.data.records
                    this.infoList.forEach((item)=>{
                        if(item.id == this.val){
                            this.workList = item
                        }
                    })
                });
            },
            getFootList(){
                postApiConfig("/pc/page/webInfo",{}).then((res) => {
                    // console.log("网站信息",res)
                    this.footList = res.data.records[0]
                });
            },
            imgLoad(){
                this.$nextTick(()=>{
                    this.bannerHeight = this.$refs.bannerHeight.height;
                })
            },
            //   点击logo
            selectImg() {
                this.$router.push("/");
            },
            // 选择导航栏
            selectNav(nav) {
                this.selectUrl = nav.path;
                this.$router.push(nav.path);
            },
            // 获取导航栏
            get_nav() {
                this.navData = this.$router.options.routes[0].children;
                this.selectUrl = this.$router.currentRoute.path;
            },
            // 投递
            joinWork(){
                this.open = true
                this.form = {};
                // console.log("workList",this.workList)
                this.form.position = this.workList.id
            },
            // 选择岗位
            changePost(){
                this.$forceUpdate();
            },
            //
            reset(){
                this.open = false
            },
            submitForm(){
                // console.log("是个啥",this.form)
                this.infoList.forEach((item)=>{
                    if(item.id == this.form.position){
                        this.form.position = item.postName
                    }
                })

                this.$refs['form'].validate(async (valid) => {
                    if (valid) {
                        postApiConfig("/pc/page/saveDelivery",this.form).then(res => {
                            if (res.code == 200) {
                                this.open = false
                                this.$message.success(res.msg)
                                this.form = {}
                            }
                        })
                    } else {
                        return false;
                    }
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .bgColor{
        background-color: rgba(255,255,255,1);
        border-bottom: 1px solid #efefef;
    }
    .bgColorNo{
        background-color: rgba(255,255,255,0);
    }
    .page{
        width: 100%;
        .content {
            width: 1200px;
            padding: 100px 0;
            margin: auto;
        }
    }
    // 轮播图
    .slideshow {
        position: relative;
        overflow-x: hidden;
        img{
            width: 100%;
            height: auto;
        }
        // 标语开始
        .slogan {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 9;
            width: 100%;
            .item {
                width: 1200px;
                margin: auto;
                color: #040030;
            }
            .main {
                font-size: 60px;
                margin-top: 30px;
                font-weight: bold;
                font-family: 'Alimama_ShuHeiTi_Bold';
                position: relative;
                &::after{
                    content: "";
                    position: absolute;
                    top: 60px;
                    left: 60px;
                    width: 200px;
                    height: 24px;
                    background-color: #2478FF;
                    z-index: -1;
                }
            }
            .assistant {
                font-size: 60px;
                font-weight: bold;
                font-family: 'Alimama_ShuHeiTi_Bold';
            }
        }
    }

    .nav_wrap {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 99;
        nav {
            width: 1200px;
            height: 80px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: auto;
            box-sizing: border-box;
            .nav_right{
                flex: 1;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .logo_img{
                    width: 50%;
                    height: auto;
                }
            }
            .nav_left {
                flex: 2;
                ul {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    font-size: 22px;
                    font-weight: 500;
                    .item{
                        padding: 0 20px;
                        color: #4C4C65;
                        &:last-child{
                            padding: 0  0  0 20px;
                        }
                    }
                    .item:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .brief{
        background-color: #f6f7fb;
        .brief_main{
            background-color: #FFFFFF;
            width: 1200px;
            margin: auto;
            /*height: 500px;*/
            box-sizing: border-box;
            padding: 100px 30px;
            .brief_title{
                display: flex;
                justify-content: space-between;
                align-items: center;
                span{
                    font-size: 44px;
                    color: #222222;
                    font-weight: bold;
                }
            }
            .place{
                margin-top: 32px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                span{
                    font-size: 20px;
                    color: #333333;
                    padding-right: 10px;
                }
            }
            .duty{
                margin-top: 30px;
                .duty_title{
                    font-size: 22px;
                    color: #333333;
                    font-weight: bold;
                }
                .duty_text{
                    margin-top: 30px;
                    font-size: 18px;
                    color: #737A80;
                }
            }
        }
    }

    .footerWrap {
        .footBox {
            background: #323543;
            .main {
                width: 1200px;
                padding: 30px 0;
                margin: auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .footBox_left {
                    div{
                        &:nth-child(1){
                            ul {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                font-size: 22px;
                                font-weight: 500;
                                .title{
                                    color: #FFFFFF;
                                    font-size: 22px;
                                }
                                .item{
                                    padding: 0 20px;
                                    color: #C6C7C9;
                                    &:last-child{
                                        padding: 0  0  0 20px;
                                    }
                                }
                                .item:hover {
                                    cursor: pointer;
                                }
                            }
                        }
                        &:nth-child(2){
                            padding-top: 10px;
                            div{
                                margin-top: 5px;
                                span{
                                    color: #ffffff;
                                }
                            }
                        }
                    }

                }
                .footBox_right {
                    text-align: center;
                    color: #ffffff;
                    font-size: 20px;
                    padding-right: 20px;
                }
            }

        }
        .internetWrap {
            background: #252936;
            .internetBox {
                display: flex;
                justify-content: space-between;
                padding: 15px 0;
                width: 1200px;
                margin: auto;
                font-size: 16px;
                color: #d3d3d3;
                span {
                    display: flex;
                    align-items: center;
                    color: #d3d3d3;
                    img {
                        margin-right: 10px;
                    }
                }
                span:hover {
                    color: #fff;
                }
            }
        }
    }

    .el-dialog__header .el-dialog__header{
        line-height: normal;
    }

    .delivery{
        width: 100%;
        /*height: 300px;*/
    }
</style>

<template>
    <div class="research_box">
        <div class="genre_box">
            <div class="introduce_box" v-for="(item,index) in workList" :key="index">
                <div class="introduce_main" @click="goDetails(item)">
                    <div class="introduce_title">
                        <span>
                            {{item.postName}}
                        </span>
                        <span>
                            {{item.city}}
                        </span>
                    </div>
                    <div class="subtitle">
                        <span>
                            {{item.workTime}}
                        </span>
                        <span>|</span>
                        <span>
                            {{item.education}}
                        </span>
                    </div>
                    <div class="brief">
                        <span v-html="item.job"></span>
<!--                        <span>-->
<!--                            {{ item.job }}-->
<!--                        </span>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // import { workJs } from "../../utils/work.js";
    export default {
        name: "research",
        data(){
            return{
                // workList:workJs(),

                workList:''
            }
        },
        props: {
            researchValue:[],
        },
        watch:{
            researchValue:{
                handler(val){
                    if(val){
                        this.workList = val
                    }
                },
                immediate: true,
                // deep:true,
            }
        },
        methods:{
            goDetails(item){
                // console.log(item)
                this.$router.push({
                    path: `/usDetails`,
                    query: { val: item.id },
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .research_box{
        width: 100%;
        .genre_box{
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .introduce_box{
                width: 48%;
                box-sizing: border-box;
                .introduce_main{
                    background-color: #FFFFFF;
                    padding: 30px;
                    box-shadow: 0 15px 10px -18px #000000;
                    border-radius: 5px;
                    margin-bottom: 30px;
                    height: 262px;
                    box-sizing: border-box;
                    .introduce_title{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 25px;
                        span{
                            color: #040030;
                            font-size: 24px;
                            &:nth-child(1){
                                font-weight: bold;
                            }
                            &:nth-child(1){
                            }
                        }
                    }
                    .subtitle{
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        margin-bottom: 47px;
                        span{
                            font-size: 20px;
                            color: #303753;
                            padding-right: 20px;
                        }
                    }
                    .brief{
                        overflow: hidden;
                        span{
                            color: #737682;
                            font-size: 18px;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 3;
                        }
                    }
                }
            }
        }
    }

</style>

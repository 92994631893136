<template>
    <div class="page">
        <div class="content">
            <div class="title">
                <div class="title_box">
                    <span>软著证书展示</span>
                </div>
            </div>
            <div class="text">
                <el-carousel :interval="4000" indicator-position="none" type="card" :height="mediumHeight +'px'">
                    <el-carousel-item v-for="(item,index) in certificate" :key="index">
                        <div class="medium_box">
                            <img ref="mediumHeight" :src="imgUrl + item.certificate" alt="" @load="imgLoad">
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="icon">

            </div>
        </div>
    </div>
</template>

<script>
    import {postApiConfig} from "@/api/apiConfig.js";
    export default {
        name: "box_5",
        data(){
            return{
                mediumHeight:'',
                certificate:[],
            }
        },
        mounted() {
            this.$nextTick(()=>{
                this.getList();
            })
        },
        methods:{
            imgLoad(){
                this.$nextTick(() => {
                    this.mediumHeight = this.$refs.mediumHeight[0].height;
                })
            },
            getList(){
                let data = {
                    current: 1,
                    size: 9999
                }
                postApiConfig('/pc/page/certificate',data).then((response)=>{
                    // console.log("首页-软著证书",response)
                    this.certificate = response.data.records
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .page {
        width: 100%;
        background-size: 100% 100%;

        .content {
            width: 1200px;
            padding: 100px 0;
            margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .title{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                .title_box{
                    width: 40%;
                    background-image: url("../../assets/img/home/icon_1.png");
                    background-repeat: no-repeat;
                    background-position: center center;
                    text-align: center;
                    span{
                        color: #333333;
                        font-size: 30px;
                    }
                }
            }

            .text{
                width: 100%;
                margin-top: 30px;

                .medium_box{
                    width: 100%;
                    height: 100%;
                    /*display: flex;*/
                    /*justify-content: center;*/
                    /*align-items: center;*/
                    img{
                        width: 100%;
                    }
                }

                .el-carousel__item h3 {
                    color: #475669;
                    font-size: 14px;
                    opacity: 0.75;
                    line-height: 200px;
                    margin: 0;
                    text-align: center;
                }

                .el-carousel__item:nth-child(2n) {
                    background-color: #99a9bf;
                }

                .el-carousel__item:nth-child(2n+1) {
                    background-color: #d3dce6;
                }
            }

            .icon{
                width: 100%;
            }
        }
    }
</style>

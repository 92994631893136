<template>
    <div>
        <!-- 盒子_1开始 -->
        <box_1 />
        <!-- 盒子_1结束 -->

        <!-- 盒子_2开始 -->
        <box_2 />
        <!-- 盒子_2结束 -->

        <!-- 盒子_3开始 -->
        <box_3 />
        <!-- 盒子_3结束 -->

        <!-- 盒子_4开始 -->
        <box_4 />
        <!-- 盒子_4结束 -->

        <!-- 盒子_5开始 -->
        <box_5 />
        <!-- 盒子_5结束 -->
    </div>
</template>

<script>
    import box_1 from '@/components/home_box/box_1'
    import box_2 from '@/components/home_box/box_2'
    import box_3 from '@/components/home_box/box_3'
    import box_4 from '@/components/home_box/box_4'
    import box_5 from '@/components/home_box/box_5'
    export default {
        name: "index",
        components:{
            box_1,
            box_2,
            box_3,
            box_4,
            box_5,
        }
    }
</script>

<style scoped>

</style>

<template>
    <div class="page">
        <div class="content">
            <div class="title">
                <div>
                    <span>成功案例</span>
                </div>
            </div>
            <div class="genre_box">
                <div class="introduce_box" v-for="(item,index) in casesList" :key="index" @click="goDetails(item)">
                    <div class="introduce_main">
                        <div class="introduce_img">
                            <img :src="imgUrl + item.small" alt="">
                        </div>
                        <div class="introduce_text">
                            <div>
                                <span>{{item.name}}</span>
                            </div>
                            <div>
                                <span>{{item.blurb}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="lookOver" v-if=" !(this.total < this.size)">
                <el-button type="primary" @click="getLookOver">查看更多</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import {postApiConfig} from "@/api/apiConfig.js";
    export default {
        name: "box_1",
        data(){
            return{
                casesList:'',
                // 查询参数
                queryParams: {
                    current: 1,
                    size: 6
                },
                total:'',
                size:'',
            }
        },
        mounted() {
            this.getList()
        },
        methods:{
            goDetails(item){
                // console.log(item)
                // this.$router.push("/productDetails");
                this.$router.push({
                    path: `/casesDetails`,
                    query: { res: item.id },
                });
            },
            getList(){
                postApiConfig("/pc/page/cases",this.queryParams).then((res) => {
                    // console.log("案列", res)
                    this.casesList = res.data.records
                });
            },
            getLookOver(){
                this.casesList = '';
                this.queryParams.size += this.queryParams.size
                postApiConfig("/pc/page/cases",this.queryParams).then((res) => {
                    // console.log("案列", res)
                    this.casesList = res.data.records
                    this.total = res.data.total
                    this.size = res.data.size
                    // console.log("total",this.total)
                    // console.log("size",this.size)
                });

            }
        }
    }
</script>

<style lang="scss" scoped>
    .page {
        width: 100%;
        background-color: #f6f7fb;

        .content {
            width: 1200px;
            padding: 100px 0;
            margin: auto;
            box-sizing: border-box;

            .title {
                width: 100%;
                color: #040030;
                text-align: center;

                div {
                    &:nth-child(1) {
                        color: #333333;
                        font-size: 44px;
                    }
                }
            }

            .text_con {
                width: 100%;
            }

            .genre_box{
                margin-top: 30px;
                width: 100%;
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                .introduce_box{
                    width: 33%;
                    /*height: 360px;*/
                    padding: 0 30px;
                    box-sizing: border-box;
                    .introduce_main{
                        background-color: #FFFFFF;
                        box-shadow: 0 15px 10px -15px #909399;
                        border-radius: 10px;
                        margin-bottom: 48px;
                        transition: all 0.5s;
                        &:hover{
                            /*transform: scale(1.05);*/
                            transform: translateY(-10px);
                        }
                    }
                    .introduce_img{
                        width: 100%;
                        height: 258px;
                        overflow: hidden;
                        border-radius: 10px 10px 0  0;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .introduce_text{
                        padding: 10px 10px 20px 10px;
                        div{
                            text-align: center;
                            &:nth-child(1){
                                color: #040030;
                                font-size: 24px;
                                margin-bottom: 30px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 1;
                            }
                            &:nth-child(2){
                                color: #303753;
                                font-size: 20px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                            }
                        }
                    }
                }
            }
            .lookOver{
                padding-top: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
</style>

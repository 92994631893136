<template>
    <div class="page">
        <div class="content">
            <div class="title">
                <div class="title_box">
                    <span>定制解决方案</span>
                </div>
            </div>
            <div class="text">
                <div class="text_box">
                    <div v-for="(item,index) in caseArr" :key="index" class="caseBox" @click="caseClick(item,index)">
                        <div>
                            <img :src="item.img" alt="">
                        </div>
                        <div  :class="{ 'active' : isActive == index}">
                            <span>{{ item.title}}</span>
                        </div>
                    </div>
                </div>
                <div class="text_box">
                    <img :src="productList[isActive].img" alt="">
                </div>
                <div class="text_box">
                    <div class="main_box_title">
                        <span>
                            {{ productList[isActive].title }}
                        </span>
                    </div>
                    <div class="main_box_text">
                        <span>
                            {{ productList[isActive].text }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="icon">

            </div>
        </div>
    </div>
</template>

<script>
    import {productJs} from "@/utils/product";
    export default {
        name: "box_4",
        data(){
            return{
                productList:productJs(),
                caseArr:[
                    {
                        num: '1',
                        title: '教育&健康',
                        img: require('../../assets/img/about/icon_1.png'),
                    },{
                        num: '2',
                        title: '身份&验证',
                        img: require('../../assets/img/about/icon_2.png'),
                    },{
                        num: '3',
                        title: '工业&制造',
                        img: require('../../assets/img/about/icon_3.png'),
                    },{
                        num: '4',
                        title: '人工&智能',
                        img: require('../../assets/img/about/icon_4.png'),
                    }
                ],
                sortArr:[
                    {
                        img: require('../../assets/img/about/1.png'),
                    },{
                        img: require('../../assets/img/about/2.png'),
                    },{
                        img: require('../../assets/img/about/3.png'),
                    },{
                        img: require('../../assets/img/about/4.png'),
                    }
                ],
                isActive: 0,
            }
        },
        mounted() {
            this.copyArr  = JSON.parse(JSON.stringify(this.caseArr))
            this.caseArr[0].img = this.sortArr[0].img;
        },
        methods:{
            caseClick(val,index){
                this.caseArr  = JSON.parse(JSON.stringify(this.copyArr))
                this.caseArr[index].img = this.sortArr[index].img
                this.isActive = index
            }
        }
    }
</script>

<style scoped lang="scss">
    .page {
        width: 100%;
        background-color: #F9FAFD;
        background-size: 100% 100%;

        .content {
            width: 1200px;
            padding: 100px 0;
            margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .title{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                .title_box{
                    width: 40%;
                    background-image: url("../../assets/img/home/icon_1.png");
                    background-repeat: no-repeat;
                    background-position: center center;
                    text-align: center;
                    span{
                        color: #333333;
                        font-size: 30px;
                    }
                }
            }

            .text{
                width: 100%;
                margin-top: 30px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .text_box{
                    height: 420px;
                    &:nth-child(1){
                        width: 200px;
                        background-color: #ffffff;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        .caseBox{
                            display: flex;
                            justify-content: space-around;
                            align-items: center;
                            div{
                                &:nth-child(1){
                                    img{
                                        width: 37px;
                                        height: auto;
                                        &:hover {
                                            cursor: pointer;
                                        }
                                    }
                                }
                                &:nth-child(2){
                                    width: 110px;
                                    text-align: left;
                                    span{
                                        color: #333333;
                                        font-size: 22px;
                                        &:hover {
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }
                            .active{
                                span{
                                    color: #1882FF!important;
                                }
                            }
                        }
                    }
                    &:nth-child(2){
                        width: 603px;
                        background-color: #ffffff;
                        img{
                            width: 100%;
                            height: 100%;
                            /*object-fit: cover;*/
                            /*object-fit: contain;*/
                        }
                    }
                    &:nth-child(3){
                        width: 325px;
                        background-color: #ffffff;
                        box-sizing: border-box;
                        padding: 20px;
                        .main_box_title{
                            color: #333333;
                            font-size: 28px;
                            margin-bottom: 42px;
                        }
                        .main_box_text{
                            color: #333333;
                            font-size: 22px;
                        }
                    }
                }
            }

            .icon{
                width: 100%;
            }
        }
    }
</style>

<template>
    <div>
        <div class="pc">
            <!-- 表头开始 -->
            <Header/>
            <!-- 表头结束 -->
            <main>
                <router-view/>
            </main>
            <!-- 底部结束 -->
            <Footer />
            <!-- 底部结束 -->
        </div>
    </div>
</template>

<script>
    import Header from "@/components/header/index";
    import Footer from "@/components/footer/index";
    export default {
        name: "index",
        components:{
            Header,
            Footer,
        }

    }
</script>

<style lang="scss">
    ::-webkit-scrollbar {
        display: none;
    }
</style>

<template>
    <div>
        <!-- 盒子_1开始 -->
        <box_1 />
        <!-- 盒子_1结束 -->
    </div>
</template>

<script>
    import box_1 from '@/components/product_box/box_1'
    export default {
        name: "index",
        components: {
            box_1,
        }
    }
</script>

<style scoped>

</style>

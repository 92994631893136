import Vue from 'vue'
import App from './App.vue'
import router from './router'


// elementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
Vue.config.productionTip = false


// 图片url地址
Vue.prototype.imgUrl = process.env.VUE_APP_BASE_URl

// 跳转后返回顶部
router.afterEach(() => {
    window.scrollTo(0,0);
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

<template>
    <div>
        <!-- 返回顶部 -->
        <el-backtop :bottom="100">
            <div style="{
        height: 100%;
        width: 100%;
        background-color: #f2f5f6;
        box-shadow: 0 0 6px rgba(0,0,0, .12);
        text-align: center;
        line-height: 40px;
        color: #0c5ce9;
        border-radius: 20px;}">
                <i class="el-icon-caret-top"></i>
            </div>
        </el-backtop>
        <!-- 案列，产品-加入-轮播图 -->
        <div class="slideshow" v-show="result.detailsStatus == 2">
            <el-carousel indicator-position="none" arrow="never" :height="assistantHeight+'px'">
                <el-carousel-item v-for="(item, index) in slideshowData" :key="index">
                    <img ref="assistantHeight" :src="imgUrl + item.url" alt="" @load="assistantLoad">
                    <div class="slogan2" v-if="item.info!=''">
                        <div class="item">
                            <div class="itemLeft">
                                <div class="assistant">
                                    {{ item.info[0]}}
                                </div>
<!--                                <div class="main">-->
<!--                                    {{ item.info[1] }}-->
<!--                                </div>-->
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <!-- 首页轮播图 -->
        <div class="slideshow" v-show="result.detailsStatus == 1">
            <el-carousel arrow="never" :height="bannerHeight+'px'">
                <el-carousel-item v-for="(item, index) in imageDetails" :key="index">
                    <img ref="bannerHeight" :src="imgUrl + item.image" alt="" @load="imgLoad">
                    <div class="slogan">
                        <div class="item">
                            <div class="itemLeft">
                                <div class="assistant">
                                    {{ item.titleDetails }}
                                </div>
                                <div class="main">
                                    {{ item.textDetails }}
                                </div>
                                <div class="cases" >
                                    <el-button type="primary" @click="goDetails(item,index)">
                                        了解更多
                                        <i class="el-icon-right"></i>
                                    </el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <!-- 导航栏 -->
        <div class="nav_wrap" :class=" scrollTopVal > 100 ? 'bgColor' : 'bgColorNo'">
            <nav>
                <div class="nav_right">
                    <img @click="selectImg" :src="logoImg" alt="六湛logo" class="logo_img"/>
                </div>
                <div class="nav_left">
                    <ul class="homeLi" v-if="selectUrl == '/home' || selectUrl == '/product'">
                        <li @click="selectNav(item)" class="item itemTitle" v-for="(item, index) in navData" :key="index">
                            <span
                                    :class="{ checked: item.path == selectUrl }"
                                    v-if=" scrollTopVal < 300 ">
                                {{item.name}}
                            </span>
                            <span :class="{ rollChecked: item.path == selectUrl }" v-else>
                                {{item.name}}
                            </span>
                        </li>
                    </ul>
                    <ul class="li" v-else>
                        <li @click="selectNav(item)" class="item itemTitle" :class=" scrollTopVal > 300 ? 'new_item' :'' " v-for="(item, index) in navData" :key="index">
                            <span
                                    :class="{ new_checked: item.path == selectUrl }"
                                    v-if=" scrollTopVal < 300 ">
                                {{item.name}}
                            </span>
                            <span :class="{ rollChecked: item.path == selectUrl, }" v-else>
                                {{item.name}}
                            </span>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
    import {postApiConfig} from "@/api/apiConfig.js";
    import transparentImg from "../../assets/img/home/lz_logo.png";

    export default {
        name: "index",
        data(){
            return{
                bannerHeight:'',
                assistantHeight:'',
                logoImg: transparentImg,
                slideshowData: [],
                scrollTopVal:'',
                navData:'',
                selectUrl:'',
                casesList:[],
                banner:[
                    {
                        img: require('../../assets/img/header/bg_2.jpg'),
                    },
                    {
                        img: require('../../assets/img/header/bg_3.jpg'),
                    },
                    {
                        img: require('../../assets/img/header/bg_4.jpg'),
                    },
                    {
                        img: require('../../assets/img/header/bg_5.jpg'),
                    },
                ],
                imageDetails:'',
                result:'',
            }
        },
        watch: {
            // 监听路由
            $route(to) {
                this.selectUrl = to.path;
                // console.log("监听",this.selectUrl)
                this.getSlideshow(to.path.replace("/", ""));
            },
        },
        created() {
            this.get_nav();
            this.getSlideshow(this.selectUrl.replace("/", ""));
            this.selectUrl = this.$route.path
        },
        mounted() {

            window.addEventListener("scroll", this.handleScroll, true);

        },
        methods: {
            imgLoad() {
                this.$nextTick(() => {
                    this.bannerHeight = this.$refs.bannerHeight[0].height;
                })
            },
            assistantLoad(){
                this.$nextTick(() => {
                    this.assistantHeight = this.$refs.assistantHeight[0].height;
                    // console.log(this.assistantHeight)
                })
            },
            handleScroll() { // 监听鼠标滚动,页面滚动距顶部距离
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
                this.scrollTopVal = scrollTop
            },
            // 获取轮播图图片
            getSlideshow(type = "home") {
                postApiConfig("/pc/page/banner",{mark: type}).then((res) => {
                    // console.log("666", res)
                    res.data.records.forEach((item)=>{
                        if(item.info){
                            item.info = item.info.split(',')
                        }
                        if(item.imageDetails){
                            item.imageDetails = JSON.parse(item.imageDetails)
                        }
                    })
                    this.result = res.data.records[0];
                    // console.log(result)
                    this.imageDetails = []
                    this.slideshowData = []
                    if(this.result.detailsStatus == 1){
                        this.imageDetails = this.result.imageDetails
                    }
                    if(this.result.detailsStatus == 2){
                        this.slideshowData = res.data.records;
                        console.log("this.slideshowData",this.slideshowData)
                    }
                });
            },
            // 获取导航栏
            get_nav() {
                this.navData = this.$router.options.routes[0].children;
                this.selectUrl = this.$router.currentRoute.path;
            },
            getCasesList(){
                let data = {
                    current: 1,
                    size: 5,
                }
                postApiConfig("/pc/page/cases",data).then((response)=>{
                    console.log("首页-成功案例",response)
                    this.casesList = response.data.records
                });
            },
            // 点击logo
            selectImg() {
                this.$router.push("/");
            },
            // 选择导航栏
            selectNav(nav) {
                this.selectUrl = nav.path;
                this.$router.push(nav.path);
            },
            goDetails(){
                this.$router.push({path: `/product`,});
            },
        }
    }
</script>

<style scoped lang="scss">
    .bgColor {
        background-color: rgba(255, 255, 255, 1);
        border-bottom: 1px solid #efefef;
    }

    .bgColorNo {
        background-color: rgba(255, 255, 255, 0);
    }
    .nav_wrap {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 99;

        nav {
            width: 1200px;
            height: 80px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: auto;
            box-sizing: border-box;

            .nav_right {
                flex: 1;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .logo_img {
                    width: 50%;
                    height: auto;
                }
            }

            .nav_left {
                flex: 2;

                .homeLi {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    font-size: 22px;
                    font-weight: 500;

                    .item {
                        padding: 0 20px;
                        color: #333333;

                        &:last-child {
                            padding: 0  0  0 20px;
                        }
                    }

                    .item:hover {
                        cursor: pointer;
                    }
                }
                .li{
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    font-size: 22px;
                    font-weight: 500;

                    .item {
                        padding: 0 20px;
                        color: #FFFFff;

                        &:last-child {
                            padding: 0  0  0 20px;
                        }
                    }
                    .new_item{
                        padding: 0 20px;
                        color: #333333;

                        &:last-child {
                            padding: 0  0  0 20px;
                        }
                    }

                    .item:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    // 轮播图
    .slideshow {
        position: relative;
        overflow-x: hidden;
        img {
            width: 100%;
            height: auto;
        }
        // 标语开始
        .slogan {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 9;
            width: 100%;

            .item {
                width: 1200px;
                margin: auto;
                color: #040030;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .itemLeft{
                    width: 50%;
                    .assistant {
                        font-size: 60px;
                        font-weight: bold;
                        font-family: 'Alimama_ShuHeiTi_Bold';
                    }
                    .main {
                        font-size: 22px;
                        margin-top: 30px;
                        font-family: 'Alimama_ShuHeiTi_Bold';
                    }
                    .cases{
                        margin-top: 30px;
                    }
                }
            }
        }
        .slogan2 {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 9;
            width: 100%;

            .item {
                width: 1200px;
                margin: auto;
                color: #040030;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .itemLeft{
                    width: 50%;
                    .assistant {
                        font-size: 60px;
                        font-weight: bold;
                        font-family: 'Alimama_ShuHeiTi_Bold';
                        position: relative;
                        &:before{
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            content: '';
                            display: inline-block;
                            width: 240px;
                            height: 15px;
                            background-color: #2478FF;
                            z-index: -1;
                        }
                    }
                    .main {
                        font-size: 22px;
                        margin-top: 10px;
                        font-family: 'Alimama_ShuHeiTi_Bold';
                    }
                }
            }
        }
    }
    .slideshow{
        .el-carousel {
            .el-carousel__item:nth-child(2n) {
                background-color: #99a9bf;
            }
            .el-carousel__item:nth-child(2n+1) {
                background-color: #d3dce6;
            }
            //左箭头
            /deep/ .el-carousel__arrow--left, .el-carousel__arrow{
                bottom: 100px;
                left: 20%;
                font-size: 20px;
                color: #0a68ff;
                width: 50px;
                height: 50px;
                i{
                    font-weight: bolder;
                }
            }
            //右箭头
            /deep/ .el-carousel__arrow--right,.el-carousel__arrow{
                bottom: 100px;
                right: 70%;
                font-size: 20px;
                color: #0a68ff;
                width: 50px;
                height: 50px;
                i{
                    font-weight: bolder;
                }
            }

            /deep/ .el-carousel__indicators {// 指示器
                left: unset;
                right: 2%;
                bottom: 20px;
                transform: unset;
            }
            /deep/ .el-carousel__button {// 指示器按钮
                width: 50px;
                height: 5px;
                border: none;
                background-color: rgba(0, 0, 0, 0.2);
            }
            /deep/ .is-active .el-carousel__button {// 指示器激活按钮
                background: #0a68ff;
            }
        }
    }
</style>

<template>
    <div class="page">
        <!-- 轮播图开始 -->
        <div class="slideshow">
            <el-carousel indicator-position="none" arrow="never" :height="bannerHeight - 5 +'px'">
                <el-carousel-item>
                    <img ref="bannerHeight" src="../../assets/img/header/bg_1.png" alt="" @load="imgLoad">
                    <div class="slogan">
                        <div class="item">
                            <div class="assistant">
                                {{ bigTopic }}
                            </div>
                            <div class="main">
                                {{ smallTopic }}
                            </div>
                            <div class="productImg">
<!--                           <img :src="imgUrl + detailsList.small" alt="">-->
                            </div>
                        </div>
                    </div>

                </el-carousel-item>
            </el-carousel>
        </div>
        <!-- 导航栏开始 -->
        <div class="nav_wrap" :class=" scrollTopVal > 50 ? 'bgColor' : 'bgColorNo'">
            <nav>
                <div class="nav_right">
                    <img @click="selectImg" :src="logoImg" alt="六湛logo" class="logo_img"/>
                </div>
                <div class="nav_left">
                    <ul>
                        <li @click="selectNav(item)" class="item itemTitle" v-for="(item, index) in navData" :key="index">
                            <span :class="{ rollChecked: item.path == selectUrl }">{{item.name}}</span>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
        <!-- 简介 -->
        <div class="brief">
            <div class="brief_main">
                <div class="brief_title">
                    <span>
                        {{ detailsList.name }}
                    </span>
                </div>
                <div class="brief_text">
                    <span>
                        {{ detailsList.blurb }}
                    </span>
                </div>
                <div class="duty">
                    <div class="duty_title">
                        <span></span>
                    </div>
                    <div class="content" v-html="detailsList.info"></div>
                </div>
            </div>
        </div>
        <!-- foot -->
        <div class="footerWrap">
            <div class="footBox">
                <div class="main">
                    <div class="footBox_left">
                        <div>
                            <ul>
                                <li class="title"><span>导航</span></li>
                                <li @click="selectNav(item)" class="item" v-for="(item, index) in navData" :key="index">
                                    <span>{{item.name}}</span>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div>
                                <span>地址：</span><span>{{ infoList.address }}</span>
                            </div>
                            <div>
                                <span>电话：</span><span>{{ infoList.phone }}</span>
                            </div>
                            <div>
                                <span>邮箱：</span><span>{{ infoList.mail }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="footBox_right">
                        <div>
                            <img :src="imgUrl + infoList.code" alt="">
                        </div>
                        <div>
                            <span>了解更多请关注我们</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="internetWrap">
                <div class="internetBox">
                    <div>
                        <span>版权所有：{{ infoList.information }}</span>
                    </div>
                    <div>
                        <!--                    https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=36010802000569-->
                        <a href="https://www.beian.gov.cn/portal/registerSystemInfo" target="_blank">
                        <span>
                            <img src="../../assets/img/footer/1.png" alt="" />
                            {{ infoList.recordNumber }}
                        </span>
                        </a>
                    </div>
                    <div>
                        <a href="https://beian.miit.gov.cn" target="_blank">
                        <span>
                            {{ infoList.domain }}
                        </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import transparentImg from "../../assets/img/home/lz_logo.png";


    import {postApiConfig} from "@/api/apiConfig.js";

    export default {
        name: "details",
        data(){
            return{
                // 获取当前屏幕的高
                // bgHeight: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
                bannerHeight:'',
                logoImg:transparentImg,
                checked: false,
                navData: [],
                selectUrl: null,
                slideshowData: [],
                scrollTopVal:'',
                bigTopic:'案例详情',
                smallTopic:'我们专注于信息技术软件研发',
                res:'',
                detailsList:'',
                productList:'',
                infoList:'',
            }
        },
        watch: {
            // 监听路由
            $route(to) {
                this.selectUrl = to.path;
            },
        },
        created() {
            this.get_nav();
            this.res = this.$route.query.res;
        },
        mounted() {

            window.addEventListener("scroll", this.handleScroll, true);

            this.getFootList()
            this.getInfoList()
        },
        methods:{
            imgLoad(){
                this.$nextTick(()=>{
                    this.bannerHeight = this.$refs.bannerHeight.height;
                })
            },
            handleScroll() { // 监听鼠标滚动,页面滚动距顶部距离
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
                this.scrollTopVal = scrollTop
                // console.log("距离多少",scrollTop)
                let navWrap = document.getElementsByClassName("nav_wrap")[0];
                let itemTitle = document.getElementsByClassName("itemTitle")
                // console.log(itemTitle)
                if (scrollTop < 300 ) {
                    // navLeft
                    // 鼠标上滚  执行的的方法
                    // navWrap.style.backgroundColor = "rgba(255,255,255,0)";
                    navWrap.style.transition = "1s";
                    for(let i = 0; i < itemTitle.length; i++){
                        itemTitle[i].style.color = "#4C4C65";
                        itemTitle[i].style.transition = "1s";
                    }
                    this.logoImg = transparentImg
                } else {
                    // 鼠标下滚  执行的的方法
                    // navWrap.style.backgroundColor = "rgba(255,255,255,1)";
                    navWrap.style.transition = "1s";
                    for(let i = 0; i < itemTitle.length; i++){
                        itemTitle[i].style.color = "#4C4C65";
                        itemTitle[i].style.transition = "1s";
                    }
                }
            },
            //   点击logo
            selectImg() {
                this.$router.push("/");
            },
            // 选择导航栏
            selectNav(nav) {
                this.selectUrl = nav.path;
                this.$router.push(nav.path);
            },
            // 获取导航栏
            get_nav() {
                this.navData = this.$router.options.routes[0].children;
                this.selectUrl = this.$router.currentRoute.path;
            },
            getFootList(){
                postApiConfig('/pc/page/webInfo',{}).then((res) => {
                    // console.log("网站信息",res)
                    this.infoList = res.data.records[0]
                });
            },
            getInfoList(){
                postApiConfig("/pc/page/cases",{current: 1, size: 999}).then((res) => {
                    // console.log("案列", res)
                    this.productList = res.data.records
                    this.productList.forEach((item)=>{
                        if(item.large){
                            item.large = item.large.split(',')
                            let arr = item.large
                            arr.forEach((val,index,arr)=>{
                                arr[index] = val;
                            })
                        }
                        if(item.id == this.res){
                            this.detailsList = item
                        }
                    })
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    @import url("../../assets/style/cases.css");
    .bgColor{
        background-color: rgba(255,255,255,1);
        border-bottom: 1px solid #efefef;
    }
    .bgColorNo{
        background-color: rgba(255,255,255,0);
    }
    .page{
        width: 100%;
    }
    // 轮播图
    .slideshow {
        position: relative;
        overflow-x: hidden;
        img{
            width: 100%;
            height: auto;
        }
        // 标语开始
        .slogan {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 9;
            width: 100%;
            .item {
                width: 1200px;
                margin: auto;
                color: #040030;
                position: relative;
            }

            .assistant {
                font-size: 40px;
                font-weight: bold;
                font-family: 'Alimama_ShuHeiTi_Bold';
            }
            .main {
                font-size: 40px;
                margin-top: 30px;
                font-weight: bold;
                font-family: 'Alimama_ShuHeiTi_Bold';
                position: relative;
                &::after{
                    content: "";
                    position: absolute;
                    top: 30px;
                    left: 60px;
                    width: 200px;
                    height: 24px;
                    background-color: #2478FF;
                    z-index: -1;
                }
            }
            .productImg{
                position: absolute;
                top: -20px;
                right: 50px;
                width: 300px;
                height: auto;
            }
        }
    }

    .nav_wrap {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 99;
        nav {
            width: 1200px;
            height: 80px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: auto;
            box-sizing: border-box;
            .nav_right{
                flex: 1;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .logo_img{
                    width: 50%;
                    height: auto;
                }
            }
            .nav_left {
                flex: 2;
                ul {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    font-size: 22px;
                    font-weight: 500;
                    .item{
                        padding: 0 20px;
                        color: #4C4C65;
                        &:last-child{
                            padding: 0  0  0 20px;
                        }
                    }
                    .item:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .brief{
        width: 100%;
        background-color: #FEFEFF;
        .brief_main{
            position: relative;
            top: -50px;
            border-radius: 10px;
            /*z-index: 99;*/
            width: 1200px;
            padding: 85px;
            background-color: #FFFFFF;
            box-shadow: 0 15px 25px -15px #909399;
            margin: auto;
            /*height: 5rem;*/
            box-sizing: border-box;
            .brief_title{
                margin-bottom: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                span{
                    font-size: 32px;
                    color: #222222;
                    font-weight: bold;
                }
            }
            .brief_text{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                span{
                    font-size: 18px;
                    color: #222222;
                }
            }
            .duty{
                margin-top: 30px;
                .duty_title{
                    font-size: 22px;
                    color: #333333;
                    font-weight: bold;
                }
                .duty_text{
                    /*margin-top: 30px;*/
                    /*font-size: 18px;*/
                    color: #222222;
                    /*line-height: 36px;*/
                }
                .content{
                    width: 100%;
                }
            }
            .dutyImg{
                width: 100%;
                .div1{
                    width: 100%;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    text-align: center;
                    div{
                        width: 30%;
                        img{
                            width: 100%;
                            height: auto;
                            object-fit: cover;
                            border: 1px solid #f1f1f1;
                        }
                    }
                }
                .div2{
                    width: 100%;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    text-align: center;
                    div{
                        width: 70%;
                        img{
                            width: 100%;
                            height: auto;
                            object-fit: cover;
                            border: 1px solid #f1f1f1;
                        }
                    }
                }
                /*div{*/
                /*    width: 30%;*/
                /*    img{*/
                /*        width: 100%;*/
                /*        height: auto;*/
                /*        object-fit: cover;*/
                /*    }*/
                /*}*/

            }
        }
    }

    .footerWrap {
        .footBox {
            background: #323543;
            .main {
                width: 1200px;
                padding: 30px 0;
                margin: auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .footBox_left {
                    div{
                        &:nth-child(1){
                            ul {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                font-size: 22px;
                                font-weight: 500;
                                .title{
                                    color: #FFFFFF;
                                    font-size: 22px;
                                }
                                .item{
                                    padding: 0 20px;
                                    color: #C6C7C9;
                                    &:last-child{
                                        padding: 0  0  0 20px;
                                    }
                                }
                                .item:hover {
                                    cursor: pointer;
                                }
                            }
                        }
                        &:nth-child(2){
                            padding-top: 10px;
                            div{
                                margin-top: 5px;
                                span{
                                    color: #ffffff;
                                }
                            }
                        }
                    }

                }
                .footBox_right {
                    text-align: center;
                    color: #ffffff;
                    font-size: 20px;
                    padding-right: 20px;
                }
            }

        }
        .internetWrap {
            background: #252936;
            .internetBox {
                display: flex;
                justify-content: space-between;
                padding: 15px 0;
                width: 1200px;
                margin: auto;
                font-size: 16px;
                color: #d3d3d3;
                span {
                    display: flex;
                    align-items: center;
                    color: #d3d3d3;
                    img {
                        margin-right: 10px;
                    }
                }
                span:hover {
                    color: #fff;
                }
            }
        }
    }
</style>

<template>
    <div class="footerWrap">
        <div class="footBox">
            <div class="main">
                <div class="footBox_left">
                    <div class="link">
                        <ul>
                            <li class="title"><span>导航</span></li>
                            <li @click="selectNav(item)" class="item" v-for="(item, index) in navData" :key="index">
                                <span>{{item.name}}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="about">
                        <div>
                            <span>地址：</span><span>{{ infoList.address }}</span>
                        </div>
                        <div>
                            <span>电话：</span><span>{{ infoList.phone }}</span>
                        </div>
                        <div>
                            <span>邮箱：</span><span>{{ infoList.mail }}</span>
                        </div>
                    </div>
                </div>
                <div class="footBox_right">
                    <div>
                        <img :src="imgUrl + infoList.code" alt="">
                    </div>
                    <div>
                        <span>了解更多请关注我们</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="internetWrap">
            <div class="internetBox">
                <div>
                    <span>版权所有：{{ infoList.information }}</span>
                </div>
                <div>
                    <!--                    https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=36010802000569-->
                    <a href="https://www.beian.gov.cn/portal/registerSystemInfo" target="_blank">
                        <span>
                            <img src="../../assets/img/footer/1.png" alt="" />
                            {{ infoList.recordNumber }}
                        </span>
                    </a>
                </div>
                <div>
                    <a href="https://beian.miit.gov.cn" target="_blank">
                        <span>
                            {{ infoList.domain }}
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {postApiConfig} from "@/api/apiConfig.js";

    export default {
        name: "index",
        data() {
            return {
                navData: [],
                infoList:'',
            }
        },

        created() {
            this.get_nav();
            this.getList()
        },
        methods: {
            getList(){
                postApiConfig("/pc/page/webInfo",{}).then((res) => {
                    // console.log("网站信息",res)
                    this.infoList = res.data.records[0]
                });
            },
            // 选择导航栏
            selectNav(nav) {
                this.selectUrl = nav.path;
                this.$router.push(nav.path);
            },
            // 获取导航栏
            get_nav() {
                this.navData = this.$router.options.routes[0].children;
                this.selectUrl = this.$router.currentRoute.path;
            },
        }

    }
</script>

<style lang="scss" scoped>
    .footerWrap {
        .footBox {
            background: #323543;
            .main {
                width: 1200px;
                padding: 30px 0;
                margin: auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .footBox_left {
                    .link{
                        ul {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            font-size: 22px;
                            font-weight: 500;
                            .title{
                                color: #FFFFFF;
                                font-size: 22px;
                            }
                            .item{
                                padding: 0 20px;
                                color: #C6C7C9;
                                &:last-child{
                                    padding: 0  0  0 20px;
                                }
                            }
                            .item:hover {
                                cursor: pointer;
                            }
                        }
                    }
                    .about{

                        div{
                            padding-top: 10px;
                            span{
                                color: #ffffff;
                            }
                        }
                    }

                }
                .footBox_right {
                    text-align: center;
                    color: #ffffff;
                    font-size: 20px;
                    padding-right: 20px;
                }
            }

        }
        .internetWrap {
            background: #252936;
            .internetBox {
                display: flex;
                justify-content: space-between;
                padding: 15px 0;
                width: 1200px;
                margin: auto;
                font-size: 16px;
                color: #d3d3d3;
                span {
                    display: flex;
                    align-items: center;
                    color: #d3d3d3;
                    img {
                        margin-right: 10px;
                    }
                }
                span:hover {
                    color: #fff;
                }
            }
        }
    }
</style>


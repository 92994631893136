<template>
    <div>
        <!-- 盒子_1开始 -->
        <box_1 />
        <!-- 盒子_1结束 -->

        <!-- 盒子_1开始 -->
        <box_2 />
        <!-- 盒子_1结束 -->
    </div>
</template>

<script>
    import box_1 from '@/components/contact_box/box_1'
    import box_2 from '@/components/contact_box/box_2'
    export default {
        name: "index",
        components: {
            box_1,
            box_2
        }
    }
</script>

<style scoped>

</style>

<template>
    <div class="container">
        <div class="page" v-if="false">
            <div class="content">
                <div class="title">

                </div>
                <div class="text_con">
                    <div class="left_con">
                        <div class="recommend_title">
                            <span>商务合作</span>
                        </div>
                        <div class="company">
                            <span>江西六湛科技有限公司</span>
                        </div>
                        <div class="address">
                            <div>
                                <span>电话：{{ infoList.phone }}</span>
                            </div>
                            <div>
                                <span>邮箱：{{ infoList.mail }}</span>
                            </div>
                            <div>
                                <span>地址：{{ infoList.address }}</span>
                            </div>
                        </div>
                        <div class="public">
                            <div>
                                <img :src="imgUrl + infoList.code" alt="">
                            </div>
                            <div>
                                了解更多请关注我们
                            </div>
                        </div>
                    </div>
                    <div class="right_box">
                        <div class="recommend_title">
                            <span>信息填写</span>
                        </div>
                        <div class="form">
                        </div>
                        <div class="submit">
                            <el-button type="primary" @click="submitForm" style="width: 120px;">提 交</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="suspension" v-if="scrollTopVal > 500">
            <div class="suspension_header">
                <span>商务合作</span>
            </div>
            <div class="suspension_text">
                <div>
                    <span>张经理</span>
                </div>
                <div>
                    137-9944-1154
                </div>
            </div>
            <div class="suspension_main">
                <div>
                    <el-input v-model="form.name" placeholder="您的姓名（必填）"></el-input>
                </div>
                <div>
                    <el-input v-model="form.phone" placeholder="联系方式（必填）" maxlength="11" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
                </div>
                <div>
                    <el-input
                            type="textarea"
                            :rows="3"
                            placeholder="请在此输入备注留言，我们将尽快与您联系"
                            v-model="form.remark">
                    </el-input>
                </div>
            </div>
            <div class="suspension_foot">
                <el-button type="primary" size="mini" @click="submitForm" style="width: 120px;">提 交</el-button>
            </div>
        </div>
    </div>
</template>

<script>

    import {postApiConfig} from "@/api/apiConfig.js";

    export default {
        name: "box_2",
        data(){
            return{
                bannerHeight:'',
                form:{
                    name:'',
                    phone:'',
                    remark:'',
                },
                screenWidth: null,

                //
                infoList:'',
                postList:'',
                rules:{
                    name:[{required: true, message: '请输入姓名', trigger: 'blur'}],
                    position:[{required: true, message: '请输入应聘岗位', trigger: 'blur'}],
                    phone:[{required: true, message: '请输入联系方式', trigger: 'blur'}],
                    mail:[{required: false, message: '请输入邮箱', trigger: 'blur'}],
                    remark:[{required: true, message: '请输入留言备注', trigger: 'blur'}],
                },
                // 滚动高度
                scrollTopVal:"",
            }
        },
        mounted() {
            // this.imgLoad();
            window.addEventListener('resize',() => {
                this.bannerHeight = this.$refs.bannerHeight.height;
                this.imgLoad();
            },false)
            this.screenWidth = document.body.clientWidth
            window.onresize = () => {
                return (() => {
                    this.screenWidth = document.body.clientWidth
                })()
            }

            this.getList();
            this.getPostList();

            window.addEventListener("scroll", this.handleScroll, true);
        },
        methods: {
            imgLoad() {
                this.$nextTick(() => {
                    this.bannerHeight = this.$refs.bannerHeight.height;
                })
            },
            getList(){
                postApiConfig("/pc/page/webInfo",{}).then((res) => {
                    // console.log("网站信息",res)
                    this.infoList = res.data.records[0]
                });
            },
            getPostList(){
                postApiConfig("/pc/page/recruit",{}).then((res) => {
                    // console.log("岗位信息",res)
                    this.postList = res.data.records
                });
            },
            handleScroll() { // 监听鼠标滚动,页面滚动距顶部距离
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
                this.scrollTopVal = scrollTop
                if (this.scrollTopVal < 500){
                    this.form = {}
                }
            },
            // 选择岗位
            changePost(){
                this.$forceUpdate();
            },
            submitForm(){

                // this.$refs['form'].validate(async (valid) => {
                //     if (valid) {
                //
                //     } else {
                //         return false;
                //     }
                // });
                if(!this.form.name){
                    this.$message.error("姓名不能为空！")
                    return false
                }
                if(!this.form.phone){
                    this.$message.error("电话号码不能为空！")
                    return false
                }
                postApiConfig("/pc/page/saveCooperate",this.form).then(res => {
                    if (res.code == 200) {
                        this.$message.success(res.msg)
                        this.form = {}
                    }
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/style/tabs.css";
    .container{
        width: 100%;
    }
    .page {
        width: 100%;
        /*height: 800px;*/
        background-image: url("../../assets/img/contact/bg_1.png");
        background-size: 100% 100%;

        .content {
            width: 1200px;
            padding: 100px 0;
            margin: auto;

            .title {
                font-size: 44px;
                color: #040030;
            }

            .text_con {
                margin-top: 50px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .left_con{
                    width: 50%;
                    .recommend_title{
                        color: #FFFFFF;
                        text-align: left;
                        font-weight: bold;
                        margin-bottom: 79px;
                        font-size: 44px;
                    }
                    .company{
                        color: #FFFFFF;
                        text-align: left;
                        font-weight: bold;
                        margin-bottom: 64px;
                        font-size: 26px;
                    }
                    .address{
                        color: #FFFFFF;
                        text-align: left;
                        font-size: 20px;
                        div{
                            margin-bottom: 24px;
                        }
                    }
                    .public{
                        margin-top: 127px;
                        color: #FFFFFF;
                        text-align: left;
                        font-size: 20px;
                        img{
                            width: 124px;
                            height: auto;
                            margin-left: 27px;
                        }

                    }
                }
                .right_box{
                    width: 50%;
                    padding: 0 30px;
                    box-sizing: border-box;
                    .recommend_title{
                        color: #333333;
                        text-align: left;
                        font-weight: bold;
                        margin-bottom: 49px;
                        font-size: 28px;
                    }
                    .form{
                        font-size: 20px;
                        color: #333333;
                    }
                    .submit{
                        margin-top: 41px;
                    }
                }
            }
        }
    }

    .suspension{
        position: fixed;
        top: 50%;
        left: 20px;
        width: 300px;
        background-color: #ffffff;
        z-index: 9999;
        transform:translateY(-50%);
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        border-radius: 10px;
        overflow: hidden;
        .suspension_header{
            width: 100%;
            height: 50px;
            box-sizing: border-box;
            background: linear-gradient(to right, #2478FF, #0FB2FF);
            padding: 15px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            span{
                color: #FFFFFF;
                font-size: 20px;
            }
        }
        .suspension_text{
            width: 100%;
            padding: 15px;
            box-sizing: border-box;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            div{
                color: #333333;
                font-size: 18px;
            }
        }
        .suspension_main{
            width: 100%;
            padding: 0 15px;
            box-sizing: border-box;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            div{
                width: 100%;
                margin-bottom: 10px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        .suspension_foot{
            width: 100%;
            padding: 15px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

</style>

import Vue from 'vue'
import VueRouter from 'vue-router'

import Main from "@/views/index"
//
import home from "@/views/home/index"; // 主页
import cases from "@/views/cases/index"; // 成功案例
import product from "@/views/product/index"; // 产品中心
import contact from "@/views/contact/index"; // 加入我们

// 详情页
import casesDetails from "@/views/cases/details"; // 成功案例
import productDetails from "@/views/product/details"; // 产品中心
import usDetails from "@/views/contact/details"; // 加入我们

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Main,
    redirect: '/home',
    children: [{
      path: '/home',
      name: '首页',
      component: home
    },{
      path: '/cases',
      name: '成功案例',
      component: cases
    },{
      path: '/product',
      name: '产品中心',
      component: product
    },{
      path: '/contact',
      name: '加入我们',
      component: contact
    }]
  },{
    path: '/casesDetails',
    name: '案例详情',
    component: casesDetails
  },{
    path: '/productDetails',
    name: '产品详情',
    component: productDetails
  },{
    path: '/usDetails',
    name: '加入我们',
    component: usDetails
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: '/',
  routes
})

export default router

export function productJs() {
    let productList = [
        {
            img: require('../assets/img/home/bg_14.png'),
            title: '高考无纸化体检',
            text: '体检信息采集过程无纸化、体检结论数据化，提高了高考体检数据录入的准确性、真实性、及时性和规范统一性。'
        },{
            img: require('../assets/img/home/bg_11.png'),
            title: '在线身份验证入场',
            text: '身份验证终端是一款专门用于各类考试对考生身份信息进行验证的终端设备，考试阶段进行人脸指纹双重比对验证，确保考生与报名人一致，彻底杜绝替考现象。'
        },{
            img: require('../assets/img/home/bg_12.png'),
            title: '便携式移动终端设备',
            text: '蓝牙打印机，将蓝牙技术应用在打印机上，摆脱打印机连线所带来的不便，实现无线打印。'
        },{
            img: require('../assets/img/home/bg_13.png'),
            title: '教资考试自动审核',
            text: '实现考生线上资料提交，避免线下考生聚集及审核场地的安排，大大降低人力，提升审核效率。单个机器1小时的处理效率，相当于传统1个人1天的处理效率。'
        },
    ];
    return productList;
}

<template>
    <div class="page">
        <!-- 轮播图开始 -->
        <div class="slideshow">
            <el-carousel indicator-position="none" arrow="never" :height="bannerHeight - 5 +'px'">
                <el-carousel-item>
                    <img ref="bannerHeight" src="../../assets/img/header/bg_1.png" alt="" @load="imgLoad">
                    <div class="slogan">
                        <div class="item">
                            <div class="assistant">
                                {{ bigTopic }}
                            </div>
                            <div class="main">
                                {{ smallTopic }}
                            </div>
                            <div class="productImg">
                            </div>
                        </div>
                    </div>

                </el-carousel-item>
            </el-carousel>
        </div>
        <!-- 导航栏开始 -->
        <div class="nav_wrap" :class=" scrollTopVal > 50 ? 'bgColor' : 'bgColorNo'">
            <nav>
                <div class="nav_right">
                    <img @click="selectImg" :src="logoImg" alt="六湛logo" class="logo_img"/>
                </div>
                <div class="nav_left">
                    <ul>
                        <li @click="selectNav(item)" class="item itemTitle" v-for="(item, index) in navData"
                            :key="index">
                            <span :class="{ rollChecked: item.path == selectUrl }">{{item.name}}</span>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
        <!-- 简介 -->
        <div class="brief">
            <div class="brief_main">
                <div class="content">
                    <div class="leftRotation" @mouseenter="Stop()" @mouseleave="Up()">
                        <div style="width:400px;height:auto;overflow: hidden; position: relative;min-height: 400px;" :style="{ height : (bannerHeight - 5) + 'px' }" v-if="imgUrlList!=''">
                            <span style="position: absolute; top: 15px;right: 15px;color: #0c5ce9;">{{(timeImgIndex+1) + '/'+ imgUrlList.length  }}</span>
                            <img style="width: 100%; height: 100%" :src="imgUrl + timeImg" @click="look_detail" alt="" @load="imgLoad">
                        </div>
                        <div style="width:400px;height:auto;overflow: hidden; position: relative;min-height: 400px;display: flex;justify-content: center;" :style="{ height : (bannerHeight - 5) + 'px' }" v-else>
                            <img style="width: auto; height: 100%" :src="imgUrl + detailsList.small" alt="" @load="smallLoad">
                        </div>
                        <div class="push_button">
                            <div class="imgBottom_con">
                                <el-carousel :interval="3000" type="card" height="90px" style="width: 100%" indicator-position="none" :autoplay="autoplayStr" @change="slideChange">
                                    <el-carousel-item v-for="(item,index) in imgUrlList" :key="index">
                                        <div class="imgBox">
                                            <img :src="imgUrl + item" alt="">
                                        </div>
                                    </el-carousel-item>
                                </el-carousel>
                            </div>
                        </div>
                    </div>
                    <div class="con_right">
                        <div class="title">
                            <span>{{ detailsList.name }}</span>
                        </div>
                        <div class="item" v-html="detailsList.info"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- foot -->
        <div class="footerWrap">
            <div class="footBox">
                <div class="main">
                    <div class="footBox_left">
                        <div>
                            <ul>
                                <li class="title"><span>导航</span></li>
                                <li @click="selectNav(item)" class="item" v-for="(item, index) in navData" :key="index">
                                    <span>{{item.name}}</span>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div>
                                <span>地址：</span><span>{{ infoList.address }}</span>
                            </div>
                            <div>
                                <span>电话：</span><span>{{ infoList.phone }}</span>
                            </div>
                            <div>
                                <span>邮箱：</span><span>{{ infoList.mail }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="footBox_right">
                        <div>
                            <img :src="imgUrl + infoList.code" alt="">
                        </div>
                        <div>
                            <span>了解更多请关注我们</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="internetWrap">
                <div class="internetBox">
                    <div>
                        <span>版权所有：{{ infoList.information }}</span>
                    </div>
                    <div>
                        <a href="https://www.beian.gov.cn/portal/registerSystemInfo" target="_blank">
                        <span>
                            <img src="../../assets/img/footer/1.png" alt=""/>
                            {{ infoList.recordNumber }}
                        </span>
                        </a>
                    </div>
                    <div>
                        <a href="https://beian.miit.gov.cn" target="_blank">
                        <span>
                            {{ infoList.domain }}
                        </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- 图片放大 -->
        <div>
            <el-dialog title="" :visible.sync="dialogVisible" width="700px" :before-close="handleClose">
                <div style="width: 100%;">
                    <img :src="imgUrl + amplifyImgUrl" alt="" style="width: 100%;height: auto;">
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import transparentImg from "../../assets/img/home/lz_logo.png";

    import {postApiConfig} from "@/api/apiConfig.js";

    export default {
        name: "details",
        data() {
            return {
                // 获取当前屏幕的高
                bannerHeight: '',
                logoImg: transparentImg,
                checked: false,
                navData: [],
                selectUrl: null,
                slideshowData: [],
                scrollTopVal: '',
                bigTopic: '产品详情',
                smallTopic: '思考并创造不一样的智能产品',
                res: '',
                detailsList: '',
                productList: '',
                infoList: '',
                imgUrlList:[],
                // 是否自动切换
                autoplayStr:true,
                // 定时器
                time:'',
                // 定时器开关
                timeStr:true,
                slideIndex: 0,
                // 时间图片
                timeImg:'',
                timeImgIndex:'',
                //
                dialogVisible:false,
                //
                amplifyImgUrl:"",
            }
        },
        watch: {
            // 监听路由
            $route:{
                // this.selectUrl = to.path;
                // this.getSlideshow(to.path.replace("/",""));
                handler(to) {
                    this.timeStr = true
                    this.selectUrl = to.path;
                },
                // 深度观察监听
                immediate: true,
                deep: true,
            },

        },
        mounted() {
            this.imgLoad();

            window.addEventListener("scroll", this.handleScroll, true);

            this.getFootList()
            this.getInfoList();

            this.get_nav();
            // this.getSlideshow(this.selectUrl.replace("/",""));
            this.res = this.$route.query.res;
            console.log("res",this.res)
        },
        methods: {
            imgLoad() {
                this.$nextTick(() => {
                    // console.log(this.$refs.bannerHeight)
                    this.bannerHeight = this.$refs.bannerHeight.height;
                })
            },
            smallLoad(){
                this.$nextTick(() => {
                    this.bannerHeight = this.$refs.bannerHeight.height;
                })
            },
            handleScroll() { // 监听鼠标滚动,页面滚动距顶部距离
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
                this.scrollTopVal = scrollTop
                // console.log("距离多少",scrollTop)
                let navWrap = document.getElementsByClassName("nav_wrap")[0];
                let itemTitle = document.getElementsByClassName("itemTitle")
                // console.log(itemTitle)
                if (scrollTop < 300) {
                    // navLeft
                    // 鼠标上滚  执行的的方法
                    // navWrap.style.backgroundColor = "rgba(255,255,255,0)";
                    navWrap.style.transition = "1s";
                    for (let i = 0; i < itemTitle.length; i++) {
                        itemTitle[i].style.color = "#4C4C65";
                        itemTitle[i].style.transition = "1s";
                    }
                    this.logoImg = transparentImg
                } else {
                    // 鼠标下滚  执行的的方法
                    // navWrap.style.backgroundColor = "rgba(255,255,255,1)";
                    navWrap.style.transition = "1s";
                    for (let i = 0; i < itemTitle.length; i++) {
                        itemTitle[i].style.color = "#4C4C65";
                        itemTitle[i].style.transition = "1s";
                    }
                }
            },
            //   点击logo
            selectImg() {
                this.$router.push("/");
            },
            // 选择导航栏
            selectNav(nav) {
                this.selectUrl = nav.path;
                this.$router.push(nav.path);
            },
            // 获取导航栏
            get_nav() {
                this.navData = this.$router.options.routes[0].children;
                this.selectUrl = this.$router.currentRoute.path;
            },
            getFootList() {
                postApiConfig("/pc/page/webInfo",{}).then((res) => {
                    this.infoList = res.data.records[0]
                });
            },
            getInfoList() {
                postApiConfig("/pc/page/product",{current: 1, size: 999}).then((res) => {
                    console.log("产品详情", res)
                    this.productList = res.data.records
                    this.productList.forEach((item) => {
                        if (item.large) {
                            item.large = item.large.split(',')
                            let arr = item.large
                            arr.forEach((val, index, arr) => {
                                arr[index] = val;
                            })
                        }
                        if (item.id == this.res) {
                            this.detailsList = item
                            console.log("detailsList",this.detailsList)
                            if(item.large){
                                this.timeImg = item.large[0]
                                this.imgUrlList = item.large
                                this.circulate();
                            }

                        }
                    })
                });
            },
            circulate(event){
                let index = 0;
                let  value = 0;
                if(event || event === 0){
                    value = event
                    this.time = setInterval(()=>{
                        value += 1;
                        if (value >= this.imgUrlList.length){
                            value = 0
                            this.timeImg = this.imgUrlList[value]
                            this.timeImgIndex = value
                        } else {
                            this.timeImg = this.imgUrlList[value]
                            this.timeImgIndex = value
                        }
                    },3000)
                } else {
                    this.time = setInterval(()=>{
                        index += 1;
                        if (index >= this.imgUrlList.length){
                            index = 0
                            this.timeImg = this.imgUrlList[index]
                            this.timeImgIndex = index
                        } else {
                            this.timeImg = this.imgUrlList[index]
                            this.timeImgIndex = index
                        }
                    },3000)
                }
            },
            slideChange(index) {
                this.slideIndex = index;
                this.timeImgIndex = index
                this.timeImg = this.imgUrlList[index]
                // console.log("this.slideIndex",this.slideIndex)
            },
            // 查看案列详情
            look_detail(){
                this.autoplayStr = false
                this.timeStr = false
                clearInterval(this.time)
                this.amplifyImgUrl = this.timeImg
                this.dialogVisible = true
            },
            Stop(){
                clearInterval(this.time)
                this.autoplayStr = false
            },
            Up(){
                if(this.timeStr){
                    this.circulate(this.timeImgIndex)
                    this.autoplayStr = true
                }
            },
            handleClose(){
                this.timeStr = true
                this.circulate(this.timeImgIndex)
                this.dialogVisible = false
                this.autoplayStr = true
            },
        }
    }
</script>

<style scoped lang="scss">
    .bgColor {
        background-color: rgba(255, 255, 255, 1);
        border-bottom: 1px solid #efefef;
    }

    .bgColorNo {
        background-color: rgba(255, 255, 255, 0);
    }

    .page {
        width: 100%;
    }

    // 轮播图
    .slideshow {
        position: relative;
        overflow-x: hidden;
        border-bottom: 1px solid #efefef;

        img {
            width: 100%;
            height: auto;
        }

        // 标语开始
        .slogan {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 9;
            width: 100%;

            .item {
                width: 1200px;
                margin: auto;
                color: #040030;
                position: relative;
            }

            .main {
                font-size: 40px;
                margin-top: 30px;
                font-weight: bold;
                font-family: 'Alimama_ShuHeiTi_Bold';
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    top: 30px;
                    left: 60px;
                    width: 200px;
                    height: 24px;
                    background-color: #2478FF;
                    z-index: -1;
                }
            }

            .assistant {
                font-size: 40px;
                font-weight: bold;
                font-family: 'Alimama_ShuHeiTi_Bold';
            }

            .productImg {
                position: absolute;
                top: -20px;
                right: 50px;
                width: 300px;
                height: auto;
            }
        }
    }

    .nav_wrap {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 99;

        nav {
            width: 1200px;
            height: 80px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: auto;
            box-sizing: border-box;

            .nav_right {
                flex: 1;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .logo_img {
                    width: 50%;
                    height: auto;
                }
            }

            .nav_left {
                flex: 2;

                ul {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    font-size: 22px;
                    font-weight: 500;

                    .item {
                        padding: 0 20px;
                        color: #4C4C65;

                        &:last-child {
                            padding: 0 0 0 20px;
                        }
                    }

                    .item:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .brief {
        width: 100%;
        background-color: #FEFEFF;

        .brief_main {
            width: 1200px;
            /*padding: 100px 30px;*/
            /*background-color: #f6f7fb;*/
            margin: auto;
            box-sizing: border-box;

            .brief_title {
                margin-bottom: 30px;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    font-size: 32px;
                    color: #222222;
                    font-weight: bold;
                }
            }

            .brief_text {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                span {
                    font-size: 18px;
                    color: #222222;
                }
            }

            .duty {
                margin-top: 30px;

                .duty_title {
                    font-size: 22px;
                    color: #333333;
                    font-weight: bold;
                }

                .duty_text {
                    margin-top: 30px;
                    font-size: 18px;
                    color: #222222;
                    line-height: 36px;
                }
            }

            .dutyImg {
                width: 100%;

                .div1 {
                    width: 100%;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    text-align: center;

                    div {
                        width: 30%;

                        img {
                            width: 100%;
                            height: auto;
                            object-fit: cover;
                            border: 1px solid #f1f1f1;
                        }
                    }
                }

                .div2 {
                    width: 100%;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    text-align: center;

                    div {
                        width: 70%;

                        img {
                            width: 100%;
                            height: auto;
                            object-fit: cover;
                            border: 1px solid #f1f1f1;
                        }
                    }
                }

                /*div{*/
                /*    width: 30%;*/
                /*    img{*/
                /*        width: 100%;*/
                /*        height: auto;*/
                /*        object-fit: cover;*/
                /*    }*/
                /*}*/

            }
        }

        .content {
            z-index: 9;
            position: relative;
            top: -50px;
            left: 0;
            width: 100%;
            /*height: 400px;*/
            background-color: #ffffff;
            /*margin-bottom: 50px;*/
            box-shadow: rgba(0, 0, 0, 0.12) 0 1px 3px,
            rgba(0, 0, 0, 0.24) 0 1px 2px;
            display: flex;
            justify-content: space-between;

            .leftRotation {
                width: 400px;
                overflow: hidden;
                position: relative;

                &:hover {
                    .push_button {
                        bottom: 0;
                    }
                }

                img{
                    transition: all 0.2s;
                }

                .push_button {
                    width: 400px;
                    height: 100px;
                    padding: 5px;
                    box-sizing: border-box;
                    background-color: rgba(255,255,255,0.8);
                    position: absolute;
                    left: 0;
                    bottom: -120px;
                    transition: all 0.2s;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .imgBottom_con{
                        width: 80%;
                        .imgBox{
                            height: 90px;
                            text-align: center;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            img{
                                width: auto;
                                height: 100%;
                                clear: both;
                                display: block;
                                margin: auto;
                            }
                        }
                    }
                }
            }

            .con_right {
                width: 800px;
                height: 100%;
                padding: 20px;
                box-sizing: border-box;

                .title {
                    width: 100%;
                    height: 60px;
                    line-height: 60px;
                    font-size: 22px;
                    color: #040030;
                }

                .item {
                    color: #303753;
                    font-size: 18px;
                    line-height: 38px;
                }
            }
        }
    }

    .footerWrap {
        .footBox {
            background: #323543;

            .main {
                width: 1200px;
                padding: 30px 0;
                margin: auto;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .footBox_left {
                    div {
                        &:nth-child(1) {
                            ul {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                font-size: 22px;
                                font-weight: 500;

                                .title {
                                    color: #FFFFFF;
                                    font-size: 22px;
                                }

                                .item {
                                    padding: 0 20px;
                                    color: #C6C7C9;

                                    &:last-child {
                                        padding: 0 0 0 20px;
                                    }
                                }

                                .item:hover {
                                    cursor: pointer;
                                }
                            }
                        }

                        &:nth-child(2) {
                            padding-top: 10px;

                            div {
                                margin-top: 5px;

                                span {
                                    color: #ffffff;
                                }
                            }
                        }
                    }

                }

                .footBox_right {
                    text-align: center;
                    color: #ffffff;
                    font-size: 20px;
                    padding-right: 20px;
                }
            }

        }

        .internetWrap {
            background: #252936;

            .internetBox {
                display: flex;
                justify-content: space-between;
                padding: 15px 0;
                width: 1200px;
                margin: auto;
                font-size: 16px;
                color: #d3d3d3;

                span {
                    display: flex;
                    align-items: center;
                    color: #d3d3d3;

                    img {
                        margin-right: 10px;
                    }
                }

                span:hover {
                    color: #fff;
                }
            }
        }
    }



</style>

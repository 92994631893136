<template>
    <div class="page">
        <div class="content">
            <div class="title">
                <div>
                    <span>产品中心</span>
                </div>
            </div>
            <div class="tabsBox">
                <div class="tabsMain">
                    <div class="tab-pane" :class=" tabIndex == item.value ? 'shape':''" v-for="(item,index) in type" :key="index" @click="handleClick(item)">
                        {{ item.label }}
                    </div>
                </div>
            </div>
            <div class="genre_box">
                <div class="introduce_box" v-for="(item,index) in productList" :key="index" @click="goDetails(item)">
                    <div class="introduce_main">
                        <div class="introduce_img">
                            <img :src="imgUrl + item.small" alt="">
                        </div>
                        <div class="introduce_text">
                            <div>
                                <span>{{item.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {postApiConfig} from "@/api/apiConfig.js";
    export default {
        name: "box_1",
        data() {
            return {
                productList:'',
                activeName:'hardware',
                type:[
                    {label:'硬件产品',value:1,name:'hardware'},
                    {label:'服务产品',value:2,name:'service'},
                ],
                tabIndex: 1,
                // 查询参数
                queryParams: {
                    current: 1,
                    size: 999,
                },
                bannerHeight:'',
            }
        },
        mounted() {
            this.queryParams.type = 1
            this.getList();
        },
        methods:{

            goDetails(item){
                // console.log(item)
                // this.$router.push("/productDetails");
                this.$router.push({
                    path: `/productDetails`,
                    query: { res: item.id },
                });
            },
            getList(){
                postApiConfig("/pc/page/product",this.queryParams).then((res) => {
                    console.log("产品", res)
                    this.productList = res.data.records
                });
            },
            handleClick(data){
                // console.log(data)
                this.productList = '';
                this.tabIndex = data.value
                switch (data.value) { // 下标
                    case 1:
                        this.queryParams.type = data.value
                        this.getList()
                        break;
                    case 2:
                        this.queryParams.type = data.value
                        this.getList()
                        break;
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../../assets/style/tabs.css";
    .page {
        width: 100%;
        background-color: #f6f7fb;

        .content {
            width: 1200px;
            padding: 100px 0;
            margin: auto;
            box-sizing: border-box;

            .title {
                width: 100%;
                color: #040030;
                text-align: center;

                div {
                    &:nth-child(1) {
                        color: #333333;
                        font-size: 44px;
                    }
                }
            }

            .text_con {
                width: 100%;
            }
            .tabsBox{
                margin-top: 30px;
                box-sizing: border-box;
                width: 100%;
                padding: 0 30px;
                .tabsMain{
                    background-color: #ffffff;
                    padding: 10px 10px 20px 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .tab-pane{
                        flex: 1;
                        text-align: center;
                        height: 30px;
                        line-height: 30px;
                        cursor:pointer;
                        font-size: 20px;
                        color: #4C4C65;
                    }
                    .shape{
                        color: #040030;
                        font-weight: bolder;
                        position: relative;
                        &::after{
                            content: '';
                            display: inline-block;
                            width: 50px;
                            height: 4px;
                            background-color: #2478ff;
                            border-radius: 2px;
                            position: absolute;
                            bottom: -10px;
                            left: 50%;
                            margin-left: -25px;
                        }
                    }
                }
            }
            .genre_box{
                margin-top: 30px;
                width: 100%;
                min-height: 500px;
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                .introduce_box{
                    width: 33.3%;
                    /*height: 360px;*/
                    padding: 0 30px;
                    box-sizing: border-box;
                    .introduce_main{
                        background-color: #ffffff;
                        box-shadow: 0 15px 10px -15px #909399;
                        /*border-radius: 10px;*/
                        /*padding: 20px;*/
                        box-sizing: border-box;
                        margin-bottom: 40px;
                        transition: all 0.5s;
                        &:hover{
                            /*transform: scale(1.05);*/
                            transform: translateY(-10px);
                        }
                    }
                    .introduce_img{
                        width: 100%;
                        height: 352px;
                        overflow: hidden;
                        box-sizing: border-box;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-bottom: 2px dashed #f6f7fb;
                        img{
                            width: auto;
                            height: 70%;
                        }
                    }
                    .new_introduce_img{
                        width: 100%;
                        height: 352px;
                        overflow: hidden;
                        box-sizing: border-box;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .introduce_text{
                        height: 80px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        div{
                            text-align: center;
                            &:nth-child(1){
                                color: #040030;
                                font-size: 24px;
                                /*margin-bottom: 30px;*/
                            }
                            &:nth-child(2){
                                color: #303753;
                                font-size: 20px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                            }
                        }
                    }
                }
            }
        }
    }
</style>

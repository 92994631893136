export function workJs() {
    let workList = [
        // 研发
        [
            {
                position: 'Java开发工程师',
                place: '南昌市',
                education: [
                    {
                        career1: '1-3年',
                        career2: '大专以上',
                    }
                ],
                demand: '【岗位职责】<br>' +
                    '1、熟悉SpringMVC、SpringBoot开发框架；<br>' +
                    '2、精通数据库技术、数据库优化及sql优化；<br>' +
                    '3、善于沟通，有合作精神，工作态度积极，能够适应加班、出差的工作性质；<br>' +
                    '4、有完整项目完成开发经验者优先；熟练掌握Myeclipse、Eclipse、数据库客户端工具，熟悉UAP开发者优先；熟悉 Oracle、MySql数据库；能独立完成包括前端和后端开发任务；有良好的团队合作精神；<br>' +
                    '5、 软件工程或计算机相关专业，有扎实的编程基础和软件工程相关知识，完整参与过大型项目；<br>' +
                    '6、熟悉Spring，SpringBoot，Hibernate，MyBaitis等主流开源框架。<br>',
                require:''
            },
            {
            position: 'Android开发工程师',
            place: '南昌市',
            education: [
                {
                    career1: '1-3年',
                    career2: '大专以上',
                }
            ],
            demand: '【岗位职责】<br>' +
                '1.根据公司要求，基于Android平台进行应用程序的开发；<br>' +
                '2.根据项目任务计划,按时完成软件高质量编码和测试工作；<br>' +
                '3.根据产品功能模块设计，编码实现各模块功能并确保开发质量；<br>' +
                '4.参与移动平台软件框架的研究，设计和实现、关键技术验证等工作；<br>' +
                '5.规范文档的编写、维护，以及其他与项目相关工作。',
            require:'【任职要求】：<br>' +
                '1.计算机或相关专业专科以上学历，1年及以上Android平台开发经验；<br>' +
                '2.熟悉Android开发平台框架及各种特性，能够熟练掌握Android各种控件的使用特性；<br>' +
                '3.能够熟练使用glide,eventbus,okhttp,rxjava等常用第三方库；<br>' +
                '4.深度参与过移动产品开发，具有独立开发相关模块的经验；<br>' +
                '5.具有良好的面相对象编程思想，熟练运用常见的设计模式；<br>' +
                '6.精通常用软件架构模式，熟悉各种算法与数据结构、多线程、网络编程等，能够不断优化产品的性能，提高用户体验；<br>' +
                '7.熟悉SVN或Git等相关的代码管理工具。'
        },
            {
            position: 'Python开发工程师',
            place: '南昌市',
            education: [
                {
                    career1: '1-3年',
                    career2: '大专以上',
                }
            ],
            demand:'【岗位职责】 <br>' +
                '1. 参与公司数据分析项目搭建、编码实现； <br>' +
                '2. 参与公司数据分析算法研发与优化。',
            require: '【岗位职责】 <br>' +
                '1. 大专以上学历，一年以上python实际项目开发经验； <br>' +
                '2. 熟练掌握python语言； <br>' +
                '3. 有爬虫和接口开发经验',
        },
        ],
        // 商务
        [
            // {
            //     position: '销售',
            //     place: '南昌市',
            //     education: [
            //         {
            //             career1: '经验不限',
            //             career2: '大专以上',
            //         }
            //     ],
            //     demand: '1、参与项目需求分析,正确理解项目需求，按需求进行系统...<br>' +
            //         '2、根据新产品开发进度和任务分配，开发相应的软件模块..<br>' +
            //         '3、根',
            // },
            {
            position: '销售助理',
            place: '福州市',
            education: [
                {
                    career1: '经验不限',
                    career2: '大专以上',
                }
            ],
            demand: '【岗位职责】<br>' +
                '1、协助部门领导及销售人员的询价、报价等事宜，跟进货款进度。<br>' +
                '2、销售合同、标书以及商务资料的制作、录入。<br>' +
                '3、完成客户数据的登记、统计，及时准确地完成公司要求的各类日常销售报表。',
            require:'【任职要求】:<br>' +
                '1、善于沟通者优先。<br>' +
                '2、能够熟练操作office办公软件系统，熟悉公文写作规范   良好文字功底 。<br>' +
                '3、性格开朗，工作仔细，责任心强，具有良好的语言表达能力，沟通技巧以及团队合作精神。',
            welfare:'福利待遇:<br>' +
                '1、上班时间8:45-18:00，中午有休息。<br>' +
                '2、不定期员工聚餐、每年固定员工旅游，每年免费员工体检。<br>' +
                '3、每月下午茶，三节福利。'
        }, {
            position: '商务助理',
            place: '福州市',
            education: [
                {
                    career1: '经验不限',
                    career2: '大专以上',
                }
            ],
            demand: '【岗位职责】<br>' +
                '1.负责招投标相关的标书等文件的制作；<br>' +
                '2.负责与供应商的联络订单订货；<br>' +
                '3.负责跟踪仓库库存量，落实出货情况，书写销售订单；<br>' +
                '4.完成领导交办的其他工作；',
            require:'【任职要求】：<br>' +
                '1.专科以上；有行政类文员工作经验优先；<br>' +
                '2.谈吐佳，性格外向，有亲和力；具备良好的协调能力和时间管理意识；良好的职业素养和职业操守；<br>' +
                '3.熟练使用Office、WPS等办公自动化软件；'
            },
        ],
        // 职能
        [
            {
                position: '技术支持',
                place: '福州市',
                education: [
                    {
                        career1: '1-3年',
                        career2: '大专以上',
                    }
                ],
                demand: '【岗位职责】<br>' +
                    '1、负责公司及客户的设备安装、调测等技术支持工作。<br>' +
                    '2、协助解决公司及客户的各类技术问题，及时准确的把现场信息反馈给销售和研发部门。  <br>' +
                    '3、支持销售部涉及的技术项目，为客户和公司人员提供相关培训。 <br>' +
                    '4、快捷准确并按公司需求提供所有报告、数据，如周工作计划/报告等。<br>' +
                    '5、公司新产品的性能测试、分析工作及现有产品的改进工作；<br>' +
                    '6、维护公司在用相关软件平台，测试平台使用功能，提出改进建议；<br>' +
                    '7、组织产品质量问题的分析及处理工作；<br>' +
                    '8、解答客户、代理商所涉及产品的技术问题；<br>' +
                    '9、定期整理公司产品、技术方向相关的信息，完善技术工作流程，编制常见问题解决方案；<br>' +
                    '10、完成上级交办的其他工作。',
            }, {
            position: '财务会计',
            place: '福州市',
            education: [
                {
                    career1: '3-5年',
                    career2: '大专以上',
                }
            ],
            demand: '【岗位职责】<br>' +
                '1.财务相关工作经验，一般纳税人及小规模纳税人企业会计工作经验，具备财务账务处理实践工作经验和职业判断力。<br>' +
                '2.熟知国家财税法规及地方税法，能够进行有效的税务规划，规避税务风险的同时实现企业的节税目标。<br>' +
                '3.具备全面的财务知识及财务内控制度工作流程，熟悉金蝶财务软件的使用。<br>' +
                '4.具备一定的业务流程梳理能力，成本管理及财务分析能力，制度建设能力，风险管控能力，效率提升能力，对外沟通能力。<br>' +
                '5.具备较强的执行力，能够积极有效地配合上级领导对财务项目进行推进。<br>' +
                '6.具备组建财务团队的能力，基本财务培训管理经验。<br>' +
                '7.品德优、责任心强、有担当、作风严谨、效率高、执行力强，具有良好的沟通协调能力、应变能力、职业素养，严守财经纪律。<br>' +
                '8.有软件企业、高新企业方面经验的会计人员优先考虑',
        }, {
            position: '行政前台',
            place: '福州市',
            education: [
                {
                    career1: '1-3年',
                    career2: '中专/中技',
                }
            ],
            demand: '【岗位职责】<br>' +
                '1、负责公司来访人员接待，往来快递收发；<br>' +
                '2、负责办公用品采购与办公环境维护，卫生监督管理；<br>' +
                '3、协助人事招聘工作，应聘人员接待面试安排；<br>' +
                '4、负责考勤监督。团队活动组织策划。',
            require:'【任职要求】<br>' +
                '1、有销售、软件研发类人员招聘工作经验<br>' +
                '2、性格开朗热情有耐心，对工作认真负责；<br>' +
                '3、会使用招聘软件有行政经验的优先；<br>' +
                '4、能熟练运用office等办公软件。',
            welfare:'【福利待遇】<br>' +
                '大小周、法定节假日、五险、节日福利、13薪等'
        },
        ],
    ];
    return workList;
}
